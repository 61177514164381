import React from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import VisitorLayout from 'src/components/layout/VisitorLayout'
import Heading from 'src/components/typography/Heading'
import Paragraph from 'src/components/typography/Paragraph'
import { OutlinedButton } from 'src/components/buttons/Primary'
import heroRegisterTeacher from 'src/images/register/hero-register-teacher.jpg'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function TeacherRegistrationCompleted(props) {
    const { t } = useTranslation();
    const { teacherAccount } = useLocalizedRoutes();

    return (
        <VisitorLayout {...props} hero={heroRegisterTeacher} title={t('teacher.registrationCompleted.title')}>
            <div className="px-6 lg:px-0 pt-12 lg:pt-24">
                <Heading type='head-title-wrap'>{t('teacher.registrationCompleted.title')}</Heading>
                <div className="py-10">
                    <Paragraph>{t('teacher.registrationCompleted.body')}</Paragraph>
                </div>
                <OutlinedButton onClick={() => navigate(teacherAccount)}>
                    {t('teacher.registrationCompleted.button')}
                </OutlinedButton>
            </div>
        </VisitorLayout>
    )
}
